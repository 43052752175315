export const LOGIN_BEGINS = 'LOGIN_BEGINS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_BEGINS = 'LOGOUT_BEGINS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const SEND_FORGOT_PASSWORD_BEGINS = 'SEND_FORGOT_PASSWORD_BEGINS';
export const SEND_FORGOT_PASSWORD_SUCCESS = 'SEND_FORGOT_PASSWORD_SUCCESS';
export const SEND_FORGOT_PASSWORD_FAILURE = 'SEND_FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_BEGINS = 'RESET_PASSWORD_BEGINS';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const GET_USER_DETAILS_BEGINS = 'GET_USER_DETAILS_BEGINS';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE';

export const CHECK_OTP_BEGINS = 'CHECK_OTP_BEGINS';
export const CHECK_OTP_SUCCESS = 'CHECK_OTP_SUCCESS';
export const CHECK_OTP_FAILURE = 'CHECK_OTP_FAILURE';

export const GET_PRODUCT_LIST_BEGINS = 'GET_PRODUCT_LIST_BEGINS';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_FAILURE = 'GET_PRODUCT_LIST_FAILURE';

export const CLEAR_PRODUCT_LIST_SUCCESS = 'CLEAR_PRODUCT_LIST_SUCCESS';

export const GET_PRODUCT_FILITERS_BEGINS = 'GET_PRODUCT_FILITERS_BEGINS';
export const GET_PRODUCT_FILITERS_SUCCESS = 'GET_PRODUCT_FILITERS_SUCCESS';
export const GET_PRODUCT_FILITERS_FAILURE = 'GET_PRODUCT_FILITERS_FAILURE';

export const UPDATE_PRODUCT_FILTERS_SUCCESS = 'UPDATE_PRODUCT_FILTERS_SUCCESS';

export const PRODUCT_ADD_TO_WISH_LIST_BEGINS = 'PRODUCT_ADD_TO_WISH_LIST_BEGINS';
export const PRODUCT_ADD_TO_WISH_LIST_SUCCESS = 'PRODUCT_ADD_TO_WISH_LIST_SUCCESS';
export const PRODUCT_ADD_TO_WISH_LIST_FAILURE = 'PRODUCT_ADD_TO_WISH_LIST_FAILURE';

export const GET_PRODUCT_DETAILS_BEGINS = 'GET_PRODUCT_DETAILS_BEGINS';
export const GET_PRODUCT_DETAILS_SUCCESS = 'GET_PRODUCT_DETAILS_SUCCESS';
export const GET_PRODUCT_DETAILS_FAILURE = 'GET_PRODUCT_DETAILS_FAILURE';

export const SHOW_INTEREST_ON_PRODUCT_BEGINS = 'SHOW_INTEREST_ON_PRODUCT_BEGINS';
export const SHOW_INTEREST_ON_PRODUCT_SUCCESS = 'SHOW_INTEREST_ON_PRODUCT_SUCCESS';
export const SHOW_INTEREST_ON_PRODUCT_FAILURE = 'SHOW_INTEREST_ON_PRODUCT_FAILURE';

export const GET_WISH_LIST_BEGINS = 'GET_WISH_LIST_BEGINS';
export const GET_WISH_LIST_SUCCESS = 'GET_WISH_LIST_SUCCESS';
export const GET_WISH_LIST_FAILURE = 'GET_WISH_LIST_FAILURE';

export const UPDATE_WISH_LIST_SUCCESS = 'UPDATE_WISH_LIST_SUCCESS';

export const REMOVE_ALL_FROM_WISH_LIST_BEGINS = 'REMOVE_ALL_FROM_WISH_LIST_BEGINS';
export const REMOVE_ALL_FROM_WISH_LIST_SUCCESS = 'REMOVE_ALL_FROM_WISH_LIST_SUCCESS';
export const REMOVE_ALL_FROM_WISH_LIST_FAILURE = 'REMOVE_ALL_FROM_WISH_LIST_FAILURE';

export const GET_CART_LIST_BEGINS = "GET_CART_LIST_BEGINS"
export const GET_CART_LIST_SUCCESS = "GET_CART_LIST_SUCCESS"
export const GET_CART_LIST_FAILURE = "GET_CART_LIST_FAILURE"

export const UPDATE_CART_ITEM_BEGINS = "UPDATE_CART_ITEM_BEGINS"
export const UPDATE_CART_ITEM_SUCCESS = "UPDATE_CART_ITEM_SUCCESS"
export const UPDATE_CART_ITEM_FAILURE = "UPDATE_CART_ITEM_FAILURE"

export const ADD_NEW_ADDRESS_BEGINS = "ADD_NEW_ADDRESS_BEGINS"
export const ADD_NEW_ADDRESS_SUCCESS = "ADD_NEW_ADDRESS_SUCCESS"
export const ADD_NEW_ADDRESS_FAILURE = "ADD_NEW_ADDRESS_FAILURE"

export const PLACE_ORDER_BEGINS = "PLACE_ORDER_BEGINS"
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS"
export const PLACE_ORDER_FAILURE = "PLACE_ORDER_FAILURE"

export const ADD_OR_REMOVE_PRODUCTS_FROM_CART_BEGINS = "ADD_OR_REMOVE_PRODUCTS_FROM_CART_BEGINS"
export const ADD_OR_REMOVE_PRODUCTS_FROM_CART_SUCCESS = "ADD_OR_REMOVE_PRODUCTS_FROM_CART_SUCCESS"
export const ADD_OR_REMOVE_PRODUCTS_FROM_CART_FAILURE = "ADD_OR_REMOVE_PRODUCTS_FROM_CART_FAILURE"

export const UPDATE_PRODUCT_DESCRIPTION_BEGINS = "UPDATE_PRODUCT_DESCRIPTION_BEGINS"
export const UPDATE_PRODUCT_DESCRIPTION_SUCCESS = "UPDATE_PRODUCT_DESCRIPTION_SUCCESS"
export const UPDATE_PRODUCT_DESCRIPTION_FAILURE = "UPDATE_PRODUCT_DESCRIPTION_FAILURE"

export const CLEAR_CART_LIST_SUCCESS = "CLEAR_CART_LIST_SUCCESS"

export const UPDATE_CART_ITEMS_COUNT_SUCCESS = "UPDATE_CART_ITEMS_COUNT_SUCCESS"

export const ADD_NEW_UNIT_BEGINS = "ADD_NEW_UNIT_BEGINS"
export const ADD_NEW_UNIT_SUCCESS = "ADD_NEW_UNIT_SUCCESS"
export const ADD_NEW_UNIT_FAILURE = "ADD_NEW_UNIT_FAILURE"

export const GET_UNIT_LIST_BEGINS = "GET_UNIT_LIST_BEGINS"
export const GET_UNIT_LIST_SUCCESS = "GET_UNIT_LIST_SUCCESS"
export const GET_UNIT_LIST_FAILURE = "GET_UNIT_LIST_FAILURE"

export const GET_PROMOTIONS_AND_OFFERS_BEGINS = "GET_PROMOTIONS_AND_OFFERS_BEGINS"
export const GET_PROMOTIONS_AND_OFFERS_SUCCESS = "GET_PROMOTIONS_AND_OFFERS_SUCCESS"
export const GET_PROMOTIONS_AND_OFFERS_FAILURE = "GET_PROMOTIONS_AND_OFFERS_FAILURE"

export const DELETE_CUSTOM_UNIT_BEGINS = "DELETE_CUSTOM_UNIT_BEGINS"
export const DELETE_CUSTOM_UNIT_SUCCESS = "DELETE_CUSTOM_UNIT_SUCCESS"
export const DELETE_CUSTOM_UNIT_FAILURE = "DELETE_CUSTOM_UNIT_FAILURE"

export const GET_CUSTOMER_OFFERS_BEGINS = "GET_CUSTOMER_OFFERS_BEGINS"
export const GET_CUSTOMER_OFFERS_SUCCESS = "GET_CUSTOMER_OFFERS_SUCCESS"
export const GET_CUSTOMER_OFFERS_FAILURE = "GET_CUSTOMER_OFFERS_FAILURE"

export const GET_ORDERS_LIST_BEGINS = "GET_ORDERS_LIST_BEGINS"
export const GET_ORDERS_LIST_SUCCESS = "GET_ORDERS_LIST_SUCCESS"
export const GET_ORDERS_LIST_FAILURE = "GET_ORDERS_LIST_FAILURE"

export const GET_ORDER_REQUEST_DETAILS_BEGINS = "GET_ORDER_REQUEST_DETAILS_BEGINS"
export const GET_ORDER_REQUEST_DETAILS_SUCCESS = "GET_ORDER_REQUEST_DETAILS_SUCCESS"
export const GET_ORDER_REQUEST_DETAILS_FAILURE = "GET_ORDER_REQUEST_DETAILS_FAILURE"

export const GET_ORDER_ACTIVE_DETAILS_BEGINS = "GET_ORDER_ACTIVE_DETAILS_BEGINS"
export const GET_ORDER_ACTIVE_DETAILS_SUCCESS = "GET_ORDER_ACTIVE_DETAILS_SUCCESS"
export const GET_ORDER_ACTIVE_DETAILS_FAILURE = "GET_ORDER_ACTIVE_DETAILS_FAILURE"

export const GET_ORDER_HISTORY_DETAILS_BEGINS = "GET_ORDER_HISTORY_DETAILS_BEGINS"
export const GET_ORDER_HISTORY_DETAILS_SUCCESS = "GET_ORDER_HISTORY_DETAILS_SUCCESS"
export const GET_ORDER_HISTORY_DETAILS_FAILURE = "GET_ORDER_HISTORY_DETAILS_FAILURE"

export const CLEAR_ORDERS_LIST_SUCCESS = "CLEAR_ORDERS_LIST_SUCCESS"

export const ORDER_REQUEST_ADDITIONAL_NOTE_BEGINS = "ORDER_REQUEST_ADDITIONAL_NOTE_BEGINS"
export const ORDER_REQUEST_ADDITIONAL_NOTE_SUCCESS = "ORDER_REQUEST_ADDITIONAL_NOTE_SUCCESS"
export const ORDER_REQUEST_ADDITIONAL_NOTE_FAILURE = "ORDER_REQUEST_ADDITIONAL_NOTE_FAILURE"

export const ORDER_REQUEST_FEEDBACK_BEGINS = "ORDER_REQUEST_FEEDBACK_BEGINS"
export const ORDER_REQUEST_FEEDBACK_SUCCESS = "ORDER_REQUEST_FEEDBACK_SUCCESS"
export const ORDER_REQUEST_FEEDBACK_FAILURE = "ORDER_REQUEST_FEEDBACK_FAILURE"

export const POST_ORDER_RATING_BEGINS = "POST_ORDER_RATING_BEGINS"
export const POST_ORDER_RATING_SUCCESS = "POST_ORDER_RATING_SUCCESS"
export const POST_ORDER_RATING_FAILURE = "POST_ORDER_RATING_FAILURE"

export const ORDER_BUY_AGAIN_BEGINS = "ORDER_BUY_AGAIN_BEGINS"
export const ORDER_BUY_AGAIN_SUCCESS = "ORDER_BUY_AGAIN_SUCCESS"
export const ORDER_BUY_AGAIN_FAILURE = "ORDER_BUY_AGAIN_FAILURE"

export const GET_ORDERS_SUB_ORDER_DETAILS_BEGINS = "GET_ORDERS_SUB_ORDER_DETAILS_BEGINS"
export const GET_ORDERS_SUB_ORDER_DETAILS_SUCCESS = "GET_ORDERS_SUB_ORDER_DETAILS_SUCCESS"
export const GET_ORDERS_SUB_ORDER_DETAILS_FAILURE = "GET_ORDERS_SUB_ORDER_DETAILS_FAILURE"

export const GET_ORDERS_SHIPPING_DETAILS_BEGINS = "GET_ORDERS_SHIPPING_DETAILS_BEGINS"
export const GET_ORDERS_SHIPPING_DETAILS_SUCCESS = "GET_ORDERS_SHIPPING_DETAILS_SUCCESS"
export const GET_ORDERS_SHIPPING_DETAILS_FAILURE = "GET_ORDERS_SHIPPING_DETAILS_FAILURE"

export const GET_DASHBOARD_BEGINS = "GET_DASHBOARD_BEGINS"
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS"
export const GET_DASHBOARD_PURCHASE_HISTORY_SUCCESS = 'GET_DASHBOARD_PURCHASE_HISTORY_SUCCESS'
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE"

export const GET_SUB_USERS_BEGINS = "GET_SUB_USERS_BEGINS"
export const GET_SUB_USERS_SUCCESS = "GET_SUB_USERS_SUCCESS"
export const GET_SUB_USERS_FAILURE = "GET_SUB_USERS_FAILURE"

export const GET_PROFILE_BEGINS = "GET_PROFILE_BEGINS"
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS"
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE"

export const UPDATE_SUB_USER_STATUS_BEGINS = "UPDATE_SUB_USER_STATUS_BEGINS"
export const UPDATE_SUB_USER_STATUS_SUCCESS = "UPDATE_SUB_USER_STATUS_SUCCESS"
export const UPDATE_SUB_USER_STATUS_FAILURE = "UPDATE_SUB_USER_STATUS_FAILURE"

export const CREATE_SUB_USER_BEGINS = "CREATE_SUB_USER_BEGINS"
export const CREATE_SUB_USER_SUCCESS = "CREATE_SUB_USER_SUCCESS"
export const CREATE_SUB_USER_FAILURE = "CREATE_SUB_USER_FAILURE"

export const UPDATE_NOTIFICATION_SETTINGS_BEGINS = "UPDATE_NOTIFICATION_SETTINGS_BEGINS"
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS = "UPDATE_NOTIFICATION_SETTINGS_SUCCESS"
export const UPDATE_NOTIFICATION_SETTINGS_FAILURE = "UPDATE_NOTIFICATION_SETTINGS_FAILURE"

export const CHANGE_PASSWORD_BEGINS = "CHANGE_PASSWORD_BEGINS"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE"

export const GET_NOTIFICATIONS_BEGINS = "GET_NOTIFICATIONS_BEGINS"
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS"
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE"

export const NOTIFICATION_MARK_AS_READ_BEGINS = "NOTIFICATION_MARK_AS_READ_BEGINS"
export const NOTIFICATION_MARK_AS_READ_SUCCESS = "NOTIFICATION_MARK_AS_READ_SUCCESS"
export const NOTIFICATION_MARK_AS_READ_FAILURE = "NOTIFICATION_MARK_AS_READ_FAILURE"

export const DELETE_NOTIFICATIONS_BEGINS = "DELETE_NOTIFICATIONS_BEGINS"
export const DELETE_NOTIFICATIONS_SUCCESS = "DELETE_NOTIFICATIONS_SUCCESS"
export const DELETE_NOTIFICATIONS_FAILURE = "DELETE_NOTIFICATIONS_FAILURE"

export const GET_PROMOTIONS_DETAILS_BEGINS = "GET_PROMOTIONS_DETAILS_BEGINS"
export const GET_PROMOTIONS_DETAILS_SUCCESS = "GET_PROMOTIONS_DETAILS_SUCCESS"
export const GET_PROMOTIONS_DETAILS_FAILURE = "GET_PROMOTIONS_DETAILS_FAILURE"

export const GET_SIMILAR_PRODUCTS_BEGINS = "GET_SIMILAR_PRODUCTS_BEGINS"
export const GET_SIMILAR_PRODUCTS_SUCCESS = "GET_SIMILAR_PRODUCTS_SUCCESS"
export const GET_SIMILAR_PRODUCTS_FAILURE = "GET_SIMILAR_PRODUCTS_FAILURE"

export const CLEAR_PRODUCT_DETAILS_SUCCESS = "CLEAR_PRODUCT_DETAILS_SUCCESS"

export const GET_STORY_BEGINS = "GET_STORY_BEGINS"
export const GET_STORY_SUCCESS = "GET_STORY_SUCCESS"
export const GET_STORY_FAILURE = "GET_STORY_FAILURE"

export const UPDATE_UNREAD_NOTIFICATION_COUNT_SUCCESS = "UPDATE_UNREAD_NOTIFICATION_COUNT_SUCCESS"

export const CLEAR_NOTIFICATION_LIST_SUCCESS = "CLEAR_NOTIFICATION_LIST_SUCCESS"

export const CLEAR_WISHLIST_SUCCESS = "CLEAR_WISHLIST_SUCCESS"

export const CLEAR_ORDER_LIST_SUCCESS = "CLEAR_ORDER_LIST_SUCCESS"

export const GET_QUICK_INSIGHTS_BEGINS = "GET_QUICK_INSIGHTS_BEGINS"
export const GET_QUICK_INSIGHTS_SUCCESS = "GET_QUICK_INSIGHTS_SUCCESS"
export const GET_QUICK_INSIGHTS_FAILURE = "GET_QUICK_INSIGHTS_FAILURE"

export const UPDATE_DASHBOARD_ROYALITY ="UPDATE_DASHBOARD_ROYALITY"