import React, { useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Button, Typography } from '../../../../../components';
import { useDispatch } from 'react-redux';
import { actionChangePassword } from '../../../../../actions/Settings';
import { SnackMessages } from '../../../../../components/Toast';
import { EyeCloseIcon, EyeOpenIcon } from '../../../../../components/SVGIcons';
import './index.scss';

const ChangePasswordModal = ({ handleCloseModal }) => {
    const dispatch = useDispatch();
    const { showSuccessMessage, showErrorMessage } = SnackMessages();
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [isNewPasswordVisible, setNewPasswordVisible] = useState(false); 
    const [payload, setPayload] = useState({ current_password: '', new_password: '', confirm_password: '' });
    const [loading, setLoading] = useState(false);
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const validateInputs = () => {
        let isValid = true;

        if (!payload.current_password) {
            showErrorMessage('Please enter  Password');
            isValid = false;
        }
        if (!payload.new_password) {
            showErrorMessage('Please enter New Password');
            isValid = false;
        } else if (payload.new_password.length < 8) {
            showErrorMessage('Password must contain at least 8 characters');
            isValid = false;
        } else if (!passwordRegex.test(payload.new_password)) {
            showErrorMessage('Password must include uppercase, lowercase, numeric, and special characters');
            isValid = false;
        }
        if (!payload.confirm_password) {
            showErrorMessage('Please enter Confirm Password');
            isValid = false;
        } else if (payload.new_password !== payload.confirm_password) {
            showErrorMessage('New Password and Confirm Password must be the same');
            isValid = false;
        }

        return isValid;
    };

    const handleChangePassword = async () => {
        if (!validateInputs()) return; 

        setLoading(true);
        const res = await dispatch(actionChangePassword(payload));
        setLoading(false);

        if (res?.success) {
            showSuccessMessage(res?.message);
            setTimeout(() => {
                handleCloseModal();
            }, 3000);
        } else {
            showErrorMessage(res?.message || 'An error occurred while changing the password.');
        }
    };

    const handleInputChange = (event) => {
        const { value, name } = event.target;
        setPayload(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div className='change-password p-3 p-lg-4'>
            <Typography variant={'text-custom'} fontSize='text-18' fontWeight={'font-semibold'} color='color-dark01' margin='mb-3 mt-2' label={'Change Password'} />
            <Form>
                <Form.Group className='mb-3' controlId='formCurrentPassword'>
                    <InputGroup className='form-group-labeled'>
                        <Form.Control
                            type={isPasswordVisible ? 'text' : 'password'}
                            name='current_password'
                            className='neu-form form-md'
                            onChange={handleInputChange}
                            placeholder='Enter current password'
                        />
                        <div id='inputGroupAppend' className='passwordAction d-flex align-items-center justify-content-center input-group-text password-icon'>
                            {!isPasswordVisible ? <EyeCloseIcon onClick={() => setPasswordVisible(!isPasswordVisible)} /> : <EyeOpenIcon onClick={() => setPasswordVisible(!isPasswordVisible)} />}
                        </div>
                    </InputGroup>
                </Form.Group>
                <Form.Group className='mb-3' controlId='formNewPassword'>
                    <InputGroup className='form-group-labeled'>
                        <Form.Control
                            type={isNewPasswordVisible ? 'text' : 'password'}
                            name='new_password'
                            className='neu-form form-md'
                            onChange={handleInputChange}
                            placeholder='Enter new password'
                        />
                        <div id='inputGroupAppend' className='passwordAction d-flex align-items-center justify-content-center input-group-text password-icon'>
                            {!isNewPasswordVisible ? <EyeCloseIcon onClick={() => setNewPasswordVisible(!isNewPasswordVisible)} /> : <EyeOpenIcon onClick={() => setNewPasswordVisible(!isNewPasswordVisible)} />}
                        </div>
                    </InputGroup>
                </Form.Group>
                <Form.Group className='mb-3' controlId='formConfirmPassword'>
                    <InputGroup className='form-group-labeled'>
                        <Form.Control
                            type={isConfirmPasswordVisible ? 'text' : 'password'}
                            name='confirm_password'
                            className='neu-form form-md'
                            onChange={handleInputChange}
                            placeholder='Confirm new password'
                        />
                        <div id='inputGroupAppend' className='passwordAction d-flex align-items-center justify-content-center input-group-text password-icon'>
                            {!isConfirmPasswordVisible ? <EyeCloseIcon onClick={() => setConfirmPasswordVisible(!isConfirmPasswordVisible)} /> : <EyeOpenIcon onClick={() => setConfirmPasswordVisible(!isConfirmPasswordVisible)} />}
                        </div>
                    </InputGroup>
                </Form.Group>
            </Form>
            <Row className='mb-2'>
                <Col xs={6} className={'mt-2'}>
                    <Button label='Cancel' onClick={handleCloseModal} className={'w-100'} variant='secondary-shadow' />
                </Col>
                <Col xs={6} className={'mt-2'}>
                    <Button label='Update' onClick={handleChangePassword} className={'w-100'} variant='primary-shadow' disabled={loading} />
                </Col>
            </Row>
        </div>
    );
};

export default ChangePasswordModal;
