import React, { Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Avatar, Button, Card, NotFound, Spinner, Typography } from '../../components';
import CheckOTP from './CheckOTP';
import "./index.scss";


const Login = React.lazy(() => import('./Login'));
const ForgotPassword = React.lazy(() => import('./ForgotPassword'));
const ResetPassword = React.lazy(() => import('./ResetPassword'));
const Message = React.lazy(() => import('./Message'));

export const Authentication = React.memo(() => {
    const { user } = useSelector((state) => state.authReducer)
    const navigate = useNavigate()

    const openInNewTab = (url) => {
        window.open(url.split(window.location.origin).pop(), '_blank');
    };

    useEffect(() => {
        if (user?.isAuthenticated && String(user?.first_login) !== '1') {
            navigate('/dashboard');
        }
    }, [user, navigate])


    return (
        <Suspense fallback={<Spinner />}>
            <div className='authentication auth-bg'>
                <div className='row align-items-center justify-content-center h-100 my-auto'>
                    <div className='col-12 col-sm-9 col-md-10 col-lg-8 col-xl-6 col-xxl-6 '>
                        <Card variant={'login-card'}>
                            <Routes>
                                <Route path="/" element={<Navigate to="/auth/login" />} />
                                <Route path="/login" element={<Login />} />
                                <Route path="/forgot-password" element={<ForgotPassword />} />
                                <Route path="/reset-password" element={<ResetPassword />} />
                                <Route path="/login-fails" element={<Message />} />
                                <Route path="/check-otp" element={<CheckOTP />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </Card>
                    </div>
                </div>
                {/* Footer */}
                <div className='d-flex flex-column align-items-center justify-content-center gap-3 mt-3'>
                    <div className='d-flex flex-row flex-wrap align-items-center justify-content-center gap-2 auth-certificates'>
                        <Avatar bsPrefix src="/images/auth-certification.png" />
                    </div>
                    <div className='d-flex align-items-center justify-content-center flex-row flex-wrap text-copyright'>
                        <Typography variant={'copyright'} label="By continuing, you agree to the" color="color-gray02" />
                        <Button label="Terms & Conditions" variant="link-info" />
                        <Typography variant={'copyright'} label="and" color="color-gray02" />
                        <Button onClick={() => openInNewTab("https://tabquartz.com/privacy-policy/")} label="Privacy Policy" variant="link-info" />
                        <Typography variant={'copyright'} label="of TAB SURFACES." color="color-gray02" />
                    </div>
                </div>
            </div>
        </Suspense>)
})

