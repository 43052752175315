import { GET_CUSTOMER_OFFERS_BEGINS, GET_CUSTOMER_OFFERS_FAILURE, GET_CUSTOMER_OFFERS_SUCCESS, GET_DASHBOARD_BEGINS, GET_DASHBOARD_FAILURE, GET_DASHBOARD_PURCHASE_HISTORY_SUCCESS, GET_DASHBOARD_SUCCESS, GET_PROMOTIONS_AND_OFFERS_BEGINS, GET_PROMOTIONS_AND_OFFERS_FAILURE, GET_PROMOTIONS_AND_OFFERS_SUCCESS, GET_PROMOTIONS_DETAILS_BEGINS, GET_PROMOTIONS_DETAILS_FAILURE, GET_PROMOTIONS_DETAILS_SUCCESS, GET_QUICK_INSIGHTS_BEGINS, GET_QUICK_INSIGHTS_FAILURE, GET_QUICK_INSIGHTS_SUCCESS, GET_SIMILAR_PRODUCTS_BEGINS, GET_SIMILAR_PRODUCTS_FAILURE, GET_SIMILAR_PRODUCTS_SUCCESS, GET_STORY_BEGINS, GET_STORY_FAILURE, GET_STORY_SUCCESS, UPDATE_DASHBOARD_ROYALITY } from "../../constants";
import api from "../../utils/axios";

const getPromotionsAndOffersBegins = () => ({ type: GET_PROMOTIONS_AND_OFFERS_BEGINS });
const getPromotionsAndOffersSuccess = (data) => ({ type: GET_PROMOTIONS_AND_OFFERS_SUCCESS, data });
const getPromotionsAndOffersFailure = () => ({ type: GET_PROMOTIONS_AND_OFFERS_FAILURE });


const getCustomerOffersBegins = () => ({ type: GET_CUSTOMER_OFFERS_BEGINS });
const getCustomerOffersSuccess = (data) => ({ type: GET_CUSTOMER_OFFERS_SUCCESS, data });
const getCustomerOffersFailure = () => ({ type: GET_CUSTOMER_OFFERS_FAILURE });

const getDashboardBegins = () => ({ type: GET_DASHBOARD_BEGINS })
const getDashboardSuccess = (data) => ({ type: GET_DASHBOARD_SUCCESS, data })
const getPurchaseHistory =(data) => ({ type: GET_DASHBOARD_PURCHASE_HISTORY_SUCCESS, data})
const getDashboardFailure = () => ({ type: GET_DASHBOARD_FAILURE })

const getPromotionDetailsBegins = () => ({ type: GET_PROMOTIONS_DETAILS_BEGINS })
const getPromotionDetailsSuccess = (data) => ({ type: GET_PROMOTIONS_DETAILS_SUCCESS, data })
const getPromotionDetailsFailure = () => ({ type: GET_PROMOTIONS_DETAILS_FAILURE })

const getSimilarProductsBegins = () => ({ type: GET_SIMILAR_PRODUCTS_BEGINS })
const getSimilarProductsSuccess = (data) => ({ type: GET_SIMILAR_PRODUCTS_SUCCESS, data })
const getSimilarProductsFailure = () => ({ type: GET_SIMILAR_PRODUCTS_FAILURE })

const getStoryBegins = () => ({ type: GET_STORY_BEGINS })
const getStorySuccess = (data) => ({ type: GET_STORY_SUCCESS, data })
const getStoryFailure = () => ({ type: GET_STORY_FAILURE })

const getQuickInsightsBegins = () => ({ type: GET_QUICK_INSIGHTS_BEGINS })
const getQuickInsightsSuccess = (data) => ({ type: GET_QUICK_INSIGHTS_SUCCESS, data })
const getQuickInsightsFailure = () => ({ type: GET_QUICK_INSIGHTS_FAILURE })

const updateDashboardRoyalty = (data) => ({ type: UPDATE_DASHBOARD_ROYALITY,data })

// get promotions and offers
export const actionGetPromotionsAndOffers = () => async (dispatch) => {
    dispatch(getPromotionsAndOffersBegins());
    try {
        const res = await api.get('/customer/promotions/customer-promotions');
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Cart fetched successfully' }
            dispatch(getPromotionsAndOffersSuccess(data));
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(getPromotionsAndOffersFailure());
        return { success: false, message: message }
    }
}

export const actionGetCustomerOffers = () => async (dispatch) => {
    dispatch(getCustomerOffersBegins());
    try {
        const res = await api.get('/customer/offers/customer-offers');
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Offers fetched successfully' }
            dispatch(getCustomerOffersSuccess(data));
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(getCustomerOffersFailure());
        return { success: false, message: message }
    }
}
 
export const actionGetDashboard = (payLoad) => async (dispatch) => {

    dispatch(getDashboardBegins());
    try {
        const res = await api.get(`/customer/dashboard`);
        if (res.status === 200) {
            const { message, data } = res?.data ? res.data : { message: 'Dashboard fetched successfully' };
            dispatch(getDashboardSuccess(data));
            
            if (data && data.recent_orders && data.recent_orders.length > 0) {
                data.recent_orders.forEach(order => {
                    order.total_amount = parseFloat(order.total_amount).toFixed(2);
                });
            }

            return { success: true, message, data };
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(getDashboardFailure());
        return { success: false, message };
    }
}


export const actionDashboardFilter =   (payload) => async (dispatch) => {
    try {
        const res = await api.get(`/customer/dashboard-filter`, { params: payload});
        if (res.status === 200) {
            const { message, data } = res?.data ? res.data : { message: 'Dashboard fetched successfully' };

            payload?.type ==="purchase" && dispatch(getPurchaseHistory({ data:data, purchaseType: payload?.value }))
          
            payload?.type ==="revenue" &&  dispatch(updateDashboardRoyalty({data: data?.revenue, selected: payload?.value}))
            return { success: true, message, data };
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        return { success: false, message };
    }
}


// Get Promotions Details
export const actionGetPromotionDetails = (id) => async (dispatch) => {
    dispatch(getPromotionDetailsBegins());
    try {
        const res = await api.get(`/customer/promotions/image/${id}`);
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Promotion Details fetched successfully' }
            dispatch(getPromotionDetailsSuccess(data));
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(getPromotionDetailsFailure());
        return { success: false, message: message }
    }
}

// get promotions and offers
export const actionGetSimilarProducts = (payload) => async (dispatch) => {
    dispatch(getSimilarProductsBegins());
    try {
        const res = await api.get('/customer/similar-products', {params: payload});
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Similar Products fetched successfully' }
            dispatch(getSimilarProductsSuccess(data));
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(getSimilarProductsFailure());
        return { success: false, message: message }
    }
}

// GET STORY
export const actionGetStory = () => async (dispatch) => {
    dispatch(getStoryBegins());
    try {
        const res = await api.get('/customer/story');
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Story fetched successfully' }
            dispatch(getStorySuccess(data));
            return { success: true, message, data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(getStoryFailure());
        return { success: false, message: message }
    }
}

// GET QUICK INSIGHTS
export const actionGetQuickInsights = () => async (dispatch) => {
    dispatch(getQuickInsightsBegins());
    try {
        const res = await api.get('/customer/quick-insights');
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Quick insights fetched successfully' }
            dispatch(getQuickInsightsSuccess(data));
            return { success: true, message, data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(getQuickInsightsFailure());
        return { success: false, message: message }
    }
}
