import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import ChangePasswordModal from '../../Modals/ChangePassword'
import { SnackMessages } from '../../../../../components/Toast'
import React, { useState } from 'react'
import { ChangePasswordIcon } from '../../../../../components/SVGIcons'
import { Button, Dialogue, Switch, Typography } from '../../../../../components'
import { actionUpdateNotificationSettings } from '../../../../../actions/Settings'
import "./index.scss"

export const ProfileSettingsTab = React.memo(({ notification }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state?.authReducer);
    const { showSuccessMessage, showErrorMessage } = SnackMessages()
    const [isSwitchDisabled, setSwitchDisabled] = useState(false)
    const [payload, setPayload] = useState({
        email_notification: Boolean(notification?.email_notification),
        push_notification: Boolean(notification?.push_notification),
        order_updates_email_notification: Boolean(notification?.order_updates_email_notification),
        order_updates_push_notification: Boolean(notification?.order_updates_push_notification),
        promotions_email_notification: Boolean(notification?.promotions_email_notification),
        promotions_push_notification: Boolean(notification?.promotions_push_notification),
        messages_email_notification: Boolean(notification?.messages_email_notification),
        messages_push_notification: Boolean(notification?.messages_push_notification),
        new_arrival_email_notification: Boolean(notification?.new_arrival_email_notification),
        new_arrival_push_notification: Boolean(notification?.new_arrival_push_notification)
    })
    const [emailNotification, setEmailNotification] = useState(Boolean(notification?.email_notification))
    const [pushNotification, setPushNotification] = useState(Boolean(notification?.push_notification))
    const [modalShow, setModalShow] = useState({
        isOpen: false,
        type: '',
        size: '',
        children: null,
        className: ''
    })
    const [emailSubNotificationPermissions, setEmailSubNotificationPermissions] = useState([
        { id: 1, label: "Order Updates", checked: Boolean(notification?.order_updates_email_notification) },
        { id: 2, label: "Promotions", checked: Boolean(notification?.promotions_email_notification) },
        { id: 3, label: "Messages", checked: Boolean(notification?.messages_email_notification) },
        { id: 4, label: "New Arrivals", checked: Boolean(notification?.new_arrival_email_notification) }
    ])
    const [pushSubNotificationPermissions, setPushSubNotificationPermissions] = useState([
        { id: 5, label: "Order Updates", checked: Boolean(notification?.order_updates_push_notification) },
        { id: 6, label: "Promotions", checked: Boolean(notification?.promotions_push_notification) },
        { id: 7, label: "Messages", checked: Boolean(notification?.messages_push_notification) },
        { id: 8, label: "New Arrivals", checked: Boolean(notification?.new_arrival_push_notification) }
    ])

    const updateNotificationSettings = async (payload) => {
        const res = await dispatch(actionUpdateNotificationSettings(payload))
        if (res?.success) {
            showSuccessMessage(res?.message)
        } else {
            showErrorMessage(res?.message)
        }
    }

    const handleEmailNotification = (e) => {
        setSwitchDisabled(true)
        setTimeout(() => {
            setSwitchDisabled(false)
        }, 3000);
        setEmailNotification(!emailNotification)
        setPayload({ ...payload, email_notification: e?.target?.checked })
        updateNotificationSettings({ ...payload, email_notification: e?.target?.checked })
    }

    const handlePushNotification = (e) => {
        setSwitchDisabled(true)
        setTimeout(() => {
            setSwitchDisabled(false)
        }, 3000);
        setPushNotification(!pushNotification)
        setPayload({ ...payload, push_notification: e?.target?.checked })
        updateNotificationSettings({ ...payload, push_notification: e?.target?.checked })
    }

    const handleCloseModal = () => setModalShow({ isOpen: false, children: null })

    const handleChangePasswordModal = () => {
        setModalShow({ ...modalShow, isOpen: true, size: 'md', className: 'modal-secondary', children: <ChangePasswordModal handleCloseModal={handleCloseModal} /> })
    }

    const getCurrency = (currency) => {
        if (currency === "USD" || currency === "Usd" || currency === "usd") {
            return "USD"
        } else if (currency === "EURO" || currency === "Euro" || currency === "euro") {
            return "EURO"
        } else {
            return "All Currency"
        }
    }

    const handleEmailSubNotificationPermission = (e, id) => {
        setSwitchDisabled(true)
        setTimeout(() => {
            setSwitchDisabled(false)
        }, 3000);
        const updatedPermissions = emailSubNotificationPermissions.map(permission => {
            if (permission.id === id) {
                return {
                    ...permission,
                    checked: e.target.checked
                };
            }
            return permission;
        });
        setEmailSubNotificationPermissions(updatedPermissions);
        const updatedItem = updatedPermissions?.find(update => update?.id === id)
        if (updatedItem?.label === "Order Updates") {
            setPayload({ ...payload, order_updates_email_notification: updatedItem?.checked })
            updateNotificationSettings({ ...payload, order_updates_email_notification: updatedItem?.checked })
        } else if (updatedItem?.label === "Promotions") {
            setPayload({ ...payload, promotions_email_notification: updatedItem?.checked })
            updateNotificationSettings({ ...payload, promotions_email_notification: updatedItem?.checked })
        } else if (updatedItem?.label === "Messages") {
            setPayload({ ...payload, messages_email_notification: updatedItem?.checked })
            updateNotificationSettings({ ...payload, messages_email_notification: updatedItem?.checked })
        } else if (updatedItem?.label === "New Arrivals") {
            setPayload({ ...payload, new_arrival_email_notification: updatedItem?.checked })
            updateNotificationSettings({ ...payload, new_arrival_email_notification: updatedItem?.checked })
        }
    }

    const handlePushSubNotificationPermission = (e, id) => {
        setSwitchDisabled(true)
        setTimeout(() => {
            setSwitchDisabled(false)
        }, 3000);
        const updatedPermissions = pushSubNotificationPermissions.map(permission => {
            if (permission.id === id) {
                return {
                    ...permission,
                    checked: e.target.checked
                };
            }
            return permission;
        });
        setPushSubNotificationPermissions(updatedPermissions);
        const updatedItem = updatedPermissions?.find(update => update?.id === id)
        if (updatedItem?.label === "Order Updates") {
            setPayload({ ...payload, order_updates_push_notification: updatedItem?.checked })
            updateNotificationSettings({ ...payload, order_updates_push_notification: updatedItem?.checked })
        } else if (updatedItem?.label === "Promotions") {
            setPayload({ ...payload, promotions_push_notification: updatedItem?.checked })
            updateNotificationSettings({ ...payload, promotions_push_notification: updatedItem?.checked })
        } else if (updatedItem?.label === "Messages") {
            setPayload({ ...payload, messages_push_notification: updatedItem?.checked })
            updateNotificationSettings({ ...payload, messages_push_notification: updatedItem?.checked })
        } else if (updatedItem?.label === "New Arrivals") {
            setPayload({ ...payload, new_arrival_push_notification: updatedItem?.checked })
            updateNotificationSettings({ ...payload, new_arrival_push_notification: updatedItem?.checked })
        }
    }

    return (
        <div className='profile-settings-tab'>
            <Dialogue modalShow={modalShow.isOpen}
                size={modalShow.size}
                handleCloseModal={() => setModalShow({ isOpen: false, children: null })} label=""
                children={modalShow.children}
                className={modalShow.className}
            />
            <Row>
                <Col lg={6} className={'mb-4 mb-lg-0'}>
                    <Row>
                        <Col xs={12}>
                            <Typography variant={"heading4"} margin={'mb-3'} label={"Notification Settings"} />
                        </Col>
                    </Row>
                    <div className='d-flex flex-row align-items-center gap-2'>
                        <Switch variant='settings-handler' label={emailNotification ? "ON" : "OFF"} name={"email-notification"} checked={emailNotification} disabled={isSwitchDisabled} handleChange={(e) => handleEmailNotification(e)} />
                        <Typography variant={"text-custom"} fontSize="text-16" fontWeight={'font-semibold'} color={emailNotification ? "color-primary" : "color-gray"} margin="mb-0" label={"Email Notification"} />
                    </div>
                    {emailNotification ?
                        <>
                            <div className='ps-2 ps-sm-3 ps-lg-4 mt-2'>
                                {emailSubNotificationPermissions?.map((permission) => <div className='d-flex flex-row align-items-center mb-1 gap-2'>
                                    <Switch variant='settings-handler' name={`email-${permission?.id}`} label={permission?.checked ? "ON" : "OFF"} checked={permission?.checked} disabled={isSwitchDisabled} handleChange={(e) => handleEmailSubNotificationPermission(e, permission?.id)} />
                                    <Typography variant={"text-custom"} fontSize="text-14" fontWeight={'font-semibold'} color={permission?.checked ? "color-primary" : "color-gray"} margin="mb-0" label={permission?.label} />
                                </div>)}
                            </div>
                        </> :
                        <></>}
                    <div className='d-flex flex-row align-items-center mt-3 gap-2'>
                        <Switch variant='settings-handler' name={"push-notification"} label={pushNotification ? "ON" : "OFF"} checked={pushNotification} disabled={isSwitchDisabled} handleChange={(e) => handlePushNotification(e)} />
                        <Typography variant={"text-custom"} fontSize="text-16" fontWeight={'font-semibold'} color={pushNotification ? "color-primary" : "color-gray"} margin="mb-0" label={"Push Notification"} />
                    </div>
                    {pushNotification ?
                        <>
                            <div className='ps-2 ps-sm-3 ps-lg-4 mt-2'>
                                {pushSubNotificationPermissions?.map((pushPermission) => <div className='d-flex flex-row align-items-center mb-1 gap-2'>
                                    <Switch variant='settings-handler' name={`push-${pushPermission?.id}`} label={pushPermission?.checked ? "ON" : "OFF"} checked={pushPermission?.checked} disabled={isSwitchDisabled} handleChange={(e) => handlePushSubNotificationPermission(e, pushPermission?.id)} />
                                    <Typography variant={"text-custom"} fontSize="text-14" fontWeight={'font-semibold'} color={pushPermission?.checked ? "color-primary" : "color-gray"} margin="mb-0" label={pushPermission?.label} />
                                </div>)}
                            </div>
                        </> :
                        <></>}
                </Col>
                <Col lg={6}>
                    <Row>
                        <Col xs={12}>
                            <Typography variant={"heading4"} margin={'mb-2'} label={"Region & Currency"} />
                        </Col>
                    </Row>
                    <div className='mb-4'>
                        <Typography
                            variant="text-custom"
                            fontSize="text-16"
                            fontWeight="font-semibold"
                            color="color-info"
                            margin="mb-0"
                            label={user?.region && user?.region !== "." ? user.region : ""}
                            sublabel={user?.region && user?.region !== "." && user?.currency ? "/" : ""}
                            sublabel2={user?.currency ? getCurrency(user?.currency) : ""}
                        />

                    </div>
                    <div>
                        <Button label="Change Password" onClick={handleChangePasswordModal} icon={<ChangePasswordIcon />} pos="left" variant="secondary-shadow" />
                    </div>
                </Col>
            </Row>
        </div>
    )
})