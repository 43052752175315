import React, { useState } from 'react'
import Image from 'react-bootstrap/Image'
import "./index.scss"

export const Avatars = ({ variant, membership, src, rounded, fluid, roundedCircle, thumbnail, bsPrefix, onClick, errorImg, customText, icon, svgIcon  }) => {
    const [imgSrc, setimgSrc] = useState(src)

    const onError = () => setimgSrc(errorImg);


    const renderComponents = () => {
        switch (variant) {
            case "profile-image":
                return <div className='profile-wrp' membership={membership}><Image alt={imgSrc} className='profile-image' src={imgSrc} onError={onError} rounded={rounded} fluid={fluid} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} /></div>
            case "header-pro-thumb":
                return <div membership={membership}><Image alt={imgSrc} className='header-pro-thumb' src={imgSrc} rounded={rounded} onError={onError} fluid={fluid} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} /></div>
            case "logo-login":
                return <Image className='logo-login' alt={imgSrc} src={imgSrc} fluid={fluid} roundedCircle={roundedCircle} onError={onError} thumbnail={thumbnail} bsPrefix={bsPrefix} />
            case "product-item-image":
                return <div className='product-item-image position-relative' onClick={onClick}> <Image alt={imgSrc} src={imgSrc} onError={onError} fluid={fluid} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} /> <div className='d-flex flex-column position-absolute top-0 end-0 h-100 p-2 ps-1 align-items-end justify-content-end gap-3'><span className='mb-auto'>{icon}</span>{customText ? <span className='product-code'> {customText}</span> : ''}</div></div>
            case "product-detail-image":
                return <div className='product-detail-image' onClick={onClick}> <Image alt={imgSrc} src={imgSrc} onError={onError} fluid={fluid} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} /></div>
            case "dash-newsoffer-image":
                return <div className='dash-newsoffer-image' onClick={onClick}> <Image alt={imgSrc} src={imgSrc} onError={onError} fluid={fluid} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} /></div>
            case "product-detail-carousal-options":
                return <div className='product-detail-carousal-options' onClick={onClick}> <Image alt={imgSrc} src={imgSrc} onError={onError} fluid={fluid} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} /></div>
            case "gif-loader":
                return <Image className='gif-loader' alt={imgSrc} src={src} fluid={fluid} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} />
            case "cart-product-image":
                return <div className='cart-list-image' onClick={onClick}> <Image alt={imgSrc} src={imgSrc || ""} onError={onError} fluid={fluid} thumbnail={thumbnail} bsPrefix={bsPrefix} /></div>
            case "odrer-list-image":
                return <div className={`order-list-image ${icon}`} onClick={onClick}> <Image alt={imgSrc} src={imgSrc || ""} onError={onError} fluid={fluid} thumbnail={thumbnail} bsPrefix={bsPrefix} /></div>
            case "row-image":
                return <div className='row-image' onClick={onClick}> <Image src={imgSrc || ""} onError={onError} fluid={fluid} thumbnail={thumbnail} bsPrefix={bsPrefix} /></div>
          
              case "story-image":
                return <div className='story-image' onClick={onClick}> <Image alt={imgSrc} src={imgSrc} onError={onError} fluid={fluid} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} /></div>
            case "offer-image":
                return <div className='offer-image' onClick={onClick}> <Image alt={imgSrc} src={imgSrc} onError={onError} fluid={fluid} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} /></div>

            case "svg-image":
                    return <div className='row-image' onClick={onClick}> <Image src={imgSrc || ""} onError={onError} fluid={fluid} thumbnail={thumbnail} bsPrefix={bsPrefix} /></div>

                default:
                return <Image alt={imgSrc} src={imgSrc} rounded={rounded} fluid={fluid} onError={onError} roundedCircle={roundedCircle} thumbnail={thumbnail} bsPrefix={bsPrefix} />
        }
    }

    return (
        <div className='avatars'>
            {renderComponents()}
        </div>
    )
}
