import React from 'react'
import { DashBoardMenuIcon, ProductMenuIcon, OrdersMenuIcon, WishlistMenuIcon, CartMenuIcon, ProfileMenuIcon, SettingsMenuIcon, PrivacyMenuIcon, EmailAtIcon, FormLockIcon, EyeOpenIcon, EyeCloseIcon, ImportUserIcon, SuccessTickIcon, CheckTickIcon, ChatIcon, ProfileIcon, SettingsIcon, LogoutIcon, EmailIcon, PhoneIcon, LocationIcon, EditPencilIcon, CloseModalIcon, MenuCloseIcon, NotificationIcon, CartIcon, CaretRightIcon, SearchIcon, DimensionIcon, ThicknessIcon, FilterIcon, SortIcon, WishlistIcon, WishlistActiveIcon, SilverIcon, GoldIcon, PlatinumIcon, CogIcon, InterestingIcon, InterestedIcon, VisualizerIcon, FilterHeadIcon, CloseCircleIcon, SortHeadIcon, BackButtonIcon,NorthProductIcon, CaretDownIcon, ModifyCart, CartArrowRight, CartAddMore, DeleteButton, AddDescription, CartRemove, PlusIcon, OffersSliderIcon, OffersColorIcon, OffersProductIcon, OffersCategoryIcon, OffersSalesVolumeIcon } from '../../components/SVGIcons'
import { Avatar, Button, Chart, Dialogue, DialogueChildren, Search, AppBar, Tabs, Typography, Range } from '../../components'
import Sidebar from '../../components/Sidebar'
import { SnackMessages } from '../../components/Toast';
import { GlobalSpinner } from '../../components/Spinner';

export default function AllComponents() {

    const { showWarnMessage, showSuccessMessage, showErrorMessage, showInfoMessage, showToastMessage } = SnackMessages();
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <div className='container py-3 bg-body'>
            <h1 className='m-0 mb-3'>All Components</h1>

            {/* Buttons */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex justify-content-between align-items-center py-3'>
                    <h3 className='m-0'>Buttons</h3>
                    <Button label="Filter" variant="secondary-gray" icon={<FilterIcon />} />
                </div>
                <div className='card-body'>
                    <div className='d-flex flex-row flex-wrap align-items-center gap-3 py-3'>
                        <Button label="Primary" variant="primary" />
                        <Button label="Continue" variant="btn-primary-lg" />
                        <Button label="Connect with TAB" variant="btn-primary-md" />
                        <Button label="Continue" disabled variant="btn-primary-lg" />
                        <Button label="Forgot Password?" variant="link-info" />
                        <Button icon={<PlusIcon />} label="Add Nore" variant="link-secondary" />
                        <Button label="View all" variant="btn-link" />
                        <Button label="Cancel" variant="secondary" />
                        <Button label="Invite User" icon={<ImportUserIcon />} pos="right" variant="secondary-shadow" />
                        <Button label="Add to Cart" icon={<CartIcon />} variant="primary-shadow" />
                        <Button label="Select Quantity" variant="info" />
                        <Button icon={<ChatIcon />} count="4" variant="primary-rounded" />
                        <Button icon={<CartIcon />} count="4" variant="white-rounded" />
                        <Button icon={<NotificationIcon />} count="4" variant="white-rounded" />
                        <Button icon={<CogIcon />} variant="button-positive" />
                        <Button variant="button-wishlist" icon={<WishlistIcon />} />
                        <Button variant="button-wishlist" icon={<WishlistActiveIcon />} />
                        <Button variant="button-back" icon={<BackButtonIcon />} />
                    </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-3'>
                    <div className='mx-2'>
                        <Button label="Cancel" variant="secondary-shadow" />
                    </div>
                    <div className='mx-2'>
                        <Button label="Update" variant="primary-shadow" />
                    </div>
                </div>
            </div>

            {/* Icons */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Icons</h3>
                </div>
                <div className='card-body'>
                    <div className='row gx-2 m-0'>
                        {/* Form Icons */}
                        <div className='col-12 col-md-6 rounded-3'>
                            <div className='row flex-column card bg-light m-0 mb-4'>
                                <div className='card-header d-flex'>
                                    <h5 className='m-0'>Form Icons</h5>
                                </div>
                                <div className='card-body'>
                                    <div className='d-flex flex-row flex-wrap gap-2 align-items-center'>
                                        <EmailAtIcon />     {/* @ symbol in Text field */}
                                        <FormLockIcon />    {/* Lock icon in Password Field */}
                                        <EyeOpenIcon />     {/* Opened Eye icon in Password Field */}
                                        <EyeCloseIcon />    {/* Closed Eye icon in Password Field */}
                                        <SearchIcon />      {/* Seacrh Icon */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Side Menu Icons */}
                        <div className='col-12 col-md-6 rounded-3'>
                            <div className='row flex-column card bg-light m-0 mb-4'>
                                <div className='card-header d-flex'>
                                    <h5 className='m-0'>Menu Icons</h5>
                                </div>
                                <div className='card-body bg-primary'>
                                    <div className='d-flex flex-row flex-wrap gap-2 align-items-center'>
                                        <DashBoardMenuIcon />   {/* Dasboard */}
                                        <ProductMenuIcon />     {/* Product */}
                                        <OrdersMenuIcon />      {/* Orders */}
                                        <WishlistMenuIcon />    {/* Wishlist */}
                                        <CartMenuIcon />        {/* Cart */}
                                        <ProfileMenuIcon />     {/* Profile */}
                                        <SettingsMenuIcon />    {/* Settings */}
                                        <PrivacyMenuIcon />     {/* Privacy */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Button Icons */}
                        <div className='col-12 col-md-6 rounded-3'>
                            <div className='row flex-column card bg-light m-0 mb-4'>
                                <div className='card-header d-flex'>
                                    <h5 className='m-0'>Button Icons</h5>
                                </div>
                                <div className='card-body' style={{ background: "#F1F1F1" }}>
                                    <div className='d-flex flex-row flex-wrap gap-2 align-items-center'>
                                        <ImportUserIcon />  {/* Add User Icon */}
                                        <ChatIcon />        {/* Chat Icon */}
                                        <EditPencilIcon />  {/* Edit Pencil Icon */}
                                        <NotificationIcon />{/* Notification Icon */}
                                        <CartIcon />        {/* Cart Icon */}
                                        <CaretRightIcon />  {/* Caret Right Icon */}
                                        <FilterIcon />      {/* Filter Icon */}
                                        <SortIcon />        {/* Sort Icon */}
                                        <WishlistIcon />    {/* Wishlist Icon */}
                                        <WishlistActiveIcon /> {/* Wishlist Active Icon */}
                                        <CogIcon />         {/* Settings Icon */}
                                        <LogoutIcon />      {/* Logout Icon */}
                                        <InterestingIcon /> {/* Interesting Icon */}
                                        <InterestedIcon />  {/* Interested Icon */}
                                        <VisualizerIcon />  {/* Visualizer Icon */}
                                        <FilterHeadIcon />  {/* Filter Icon */}
                                        <SortHeadIcon />     {/* Sort Icon */}
                                        <CloseCircleIcon />  {/* Close Circle Icon */}
                                        <CaretDownIcon />    {/* Caret Down Icon */}
                                        <ModifyCart />       {/* Modify Cart Icon */}
                                        <CartArrowRight />   {/* Cart Arrow Right */}
                                        <CartAddMore />      {/* Cart Add More */}
                                        <CartRemove />      {/* Remove from Cart */}
                                        <DeleteButton />     {/* Delete Button */}
                                        <AddDescription />   {/* Add Description */}
                                        <OffersSliderIcon /> {/* News & Offers Slider */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Other Icons */}
                        <div className='col-12 col-md-6 rounded-3'>
                            <div className='row flex-column card bg-light m-0 mb-4'>
                                <div className='card-header d-flex'>
                                    <h5 className='m-0'>Other Icons</h5>
                                </div>
                                <div className='card-body' style={{ background: "#F1F1F1" }}>
                                    <div className='d-flex flex-row flex-wrap gap-2 align-items-center'>
                                        <SuccessTickIcon /> {/* Success Message Icon */}
                                        <CheckTickIcon />   {/* Checkbox Tick Icon */}
                                        <ProfileIcon />     {/* Dropdown Profile Icon */}
                                        <SettingsIcon />    {/* Dropdown Settings Icon */}
                                        <LogoutIcon />      {/* Dropdown Logout Icon */}
                                        <EmailIcon />       {/* Email Icon */}
                                        <PhoneIcon />       {/* Phone Icon */}
                                        <LocationIcon />    {/* Location Icon */}
                                        <CloseModalIcon />  {/* Close Modal Icon*/}
                                        <MenuCloseIcon />   {/* Close Menu Icon*/}
                                        <DimensionIcon />   {/* Dimension Icon*/}
                                        <ThicknessIcon />   {/* Thickness Icon*/}
                                        <SilverIcon width="36" height="36" />
                                        <GoldIcon width="36" height="36" />
                                        <PlatinumIcon width="36" height="36" />
                                        <NorthProductIcon /> {/* North Product Icon*/}
                                        <OffersColorIcon />         {/* News and Offers */}
                                        <OffersProductIcon />       {/* News and Offers */}
                                        <OffersCategoryIcon />      {/* News and Offers */}
                                        <OffersSalesVolumeIcon />   {/* News and Offers */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Avatars */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Avatars</h3>
                </div>
                <div className='card-body'>
                    <div className='d-flex flex-row flex-wrap align-items-center gap-3 py-3'>
                        <Avatar bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar variant={'profile-image'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar variant={'profile-image'} membership={'silver'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar variant={'profile-image'} membership={'gold'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar variant={'profile-image'} membership={'platinum'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar variant={'header-pro-thumb'} membership={'silver'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar variant={'header-pro-thumb'} membership={'gold'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar variant={'header-pro-thumb'} membership={'platinum'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                        <Avatar bsPrefix src="/images/badge-silver.svg" />
                        <Avatar bsPrefix src="/images/badge-gold.svg" />
                        <Avatar bsPrefix src="/images/badge-platinum.svg" />
                        <Avatar variant={'cart-product-image'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                    </div>
                </div>
            </div>

            {/* Typography */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Typography</h3>
                </div>
                <div className='card-body text-start'>
                    <Typography variant={'heading1'} label="News & Offers" />
                    <Typography variant={'heading2'} label="News & Offers" />
                    <Typography variant={'heading3'} label="News & Offers" />
                    <Typography variant={'heading4'} label="News & Offers" />
                    <Typography variant={'heading5'} label="News & Offers" />
                    <Typography variant={'heading6'} label="News & Offers" />
                    <Typography variant={'title'} label="Login" />
                    <div className='d-flex align-items-center flex-row flex-wrap text-copyright'>
                        <Typography variant={'copyright'} label="By continuing, you agree to the" color="color-gray02" />
                        <Button label="Terms & Conditions" variant="link-info" />
                        <Typography variant={'copyright'} label="and" color="color-gray02" />
                        <Button label="Privacy Policy" variant="link-info" />
                        <Typography variant={'copyright'} label="of TAB SURFACES." color="color-gray02" />
                    </div>
                    <Typography variant={'subtitle'} margin="mb-1" label="Welcome " sublabel="Carl Chalou" />
                    <Typography variant={'subtitle-v1'} margin="mb-3" label="We are happy to welcome you again " sublabel="Carl Chalou" />
                    <Typography variant={'subtitle1'} color="color-info" label="Unfortunately, we are facing some issues with your account." />
                    <Typography variant={'welcomeTitle'} label="Welcome Back," sublabel="Carl Chalou" />
                    <Typography variant={'subtitle1'} label="Absolute Black-bb" />
                    <Typography variant={'subtitle2'} label="NATURAL STONE" />
                    <Typography variant={'body1'} color="color-gray03" label="TAB does not try to be just another natural stone factory. We live each production process with an indescribable passion. It has dawned upon us that we are not just natural stone suppliers – We are natural stone “dreamers”. That’s how TAB describes itself.  Now, we can say the same for Engineered Quartz Surfaces as well." />
                    <Typography variant={'body2'} label="Please feel free to connect with our support executive to rectify the issue." />
                    <Typography variant={'body3'} label="2cm - 5" infoSymbol="|" sublabel="3cm - 38" />
                    <Typography variant={'body4'} label="$5,248,100" />
                    <Typography variant={'price01'} label="$ 3,456.10" />
                    <Typography variant={'profile-name'} color="color-gray" margin="mb-1" label="Carl Chalou" />
                    <Typography variant={'profile-company'} color="color-gray" margin="mb-1" label="DWYER MARBLE & STONE" />

                    <Typography variant={'text-custom'} fontSize="text-20" fontWeight="font-bold" color="color-info" margin="my-3" label="You can provide fontSize, fontWeight, color, margin & label to this text" />
                </div>
            </div>

            {/* Tabs */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Tabs</h3>
                </div>
                <div className='card-body flex-column bg-secondary'>
                    <div>
                        <Tabs data={[
                            { title: 'Slabs', children: "Tab 1 selected", disabled: false },
                            { title: 'Tiles', children: "Tab 2 selected", disabled: false },
                            { title: 'Mosaics', children: "Tab 3 selected", disabled: false },
                            { title: 'Blocks', children: "Tab 4 selected", disabled: false },
                        ]}
                            variant="tab-with-button"
                            selected={'Slabs'}
                        />
                    </div>
                    <div>
                        <Tabs data={[
                            { title: 'Request', children: "Tab 1 selected", disabled: false },
                            { title: 'Open', children: "Tab 2 selected", disabled: false },
                            { title: 'History', children: "Tab 3 selected", disabled: false },
                        ]}
                            variant="tab-with-button"
                            selected={'Open'}
                        />
                    </div>
                    <div>
                        <Tabs data={[
                            { title: 'Personal Info', children: "Tab 1 selected", disabled: false },
                            { title: 'Profile Settings', children: "Tab 2 selected", disabled: false },
                            { title: 'User Management', children: "Tab 3 selected", disabled: false },
                        ]}
                            variant="tab-with-border"
                            selected={'User Management'}
                        />
                    </div>
                </div>
            </div>

            {/* Dialogue */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Dialogue</h3>
                </div>
                <div className='card-body'>
                    <Button variant="primary"
                        onClick={(e) => setModalShow(true)}
                        label="Remove From Wish List" />
                    <Dialogue
                        modalShow={modalShow}
                        handleCloseModal={() => setModalShow(false)}
                        children={<DialogueChildren content={{
                            title: 'Do you Want to Remove,',
                            subTitle: 'From your Wish List ?',
                            label: 'Fossil M color',
                            yesButtonText: 'Yes, Remove it!',
                            noButtonText: 'No, Thanks!',
                            yesButtonIcon: <WishlistMenuIcon />,
                            yesButtonHandler: (data) => { console.log(data) },
                            noButtonhandler: (data) => { console.log(data) },
                        }} />}
                    />
                </div>
            </div>

            {/* Side Bar */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Sidebar</h3>
                </div>
                <div className='card-body'>
                    <Sidebar />
                </div>
            </div>

            {/* Toast */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Toast</h3>
                </div>
                <div className='card-body d-flex gap-4'>
                    <Button label="Normal" onClick={() => showToastMessage("This is a Info ")} variant="primary" />
                    <Button label="Success" onClick={() => showSuccessMessage("This is a Success Message")} variant="primary" />
                    <Button label="Warning" onClick={() => showWarnMessage("This is a  Warning")} variant="primary" />
                    <Button label="Error" onClick={() => showErrorMessage("This is a Error ")} variant="primary" />
                    <Button label="Info" onClick={() => showInfoMessage("This is a Info ")} variant="primary" />
                </div>
            </div>

            {/* Chart */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Chart</h3>
                </div>
                <div className='card-body d-flex gap-4'>
                    <Chart />
                </div>
            </div>

            {/* Search */}
            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Search</h3>
                </div>
                <div className='card-body d-flex gap-4 bg-secondary'>
                    <Search />
                </div>
            </div>


            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>AppBar</h3>
                </div>
                <div className='card-body d-flex gap-4 bg-secondary'>
                    <AppBar />
                </div>
            </div>


            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Splash Screen</h3>
                </div>
                <div className='card-body d-flex gap-4 bg-secondary'>
                    <GlobalSpinner />
                </div>
            </div>


            <div className='row flex-column card bg-light m-0 mb-4'>
                <div className='card-header d-flex'>
                    <h3 className='m-0'>Range</h3>
                </div>
                <div className='card-body d-flex gap-4 bg-primary'>
                    <Range variant="two-point-range"
                        label="You are just 700 points near to unlock Platinum benefits."
                        value={[800]} range={[0, 2000]} />
                    <Range variant="two-point-range"
                        label="You are just 700 points near to unlock Platinum benefits."
                        value={[1300]} range={[0, 2000]} />
                    <Range variant="three-point-range"
                        value={[1300]} range={[0, 2000]} />
                </div>
            </div>

        </div>
    )
}
