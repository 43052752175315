import { GET_CUSTOMER_OFFERS_BEGINS, GET_CUSTOMER_OFFERS_FAILURE, GET_CUSTOMER_OFFERS_SUCCESS, GET_DASHBOARD_BEGINS, GET_DASHBOARD_FAILURE, GET_DASHBOARD_PURCHASE_HISTORY_SUCCESS, GET_DASHBOARD_SUCCESS, GET_PROMOTIONS_AND_OFFERS_BEGINS, GET_PROMOTIONS_AND_OFFERS_FAILURE, GET_PROMOTIONS_AND_OFFERS_SUCCESS, GET_PROMOTIONS_DETAILS_BEGINS, GET_PROMOTIONS_DETAILS_FAILURE, GET_PROMOTIONS_DETAILS_SUCCESS, GET_QUICK_INSIGHTS_BEGINS, GET_QUICK_INSIGHTS_FAILURE, GET_QUICK_INSIGHTS_SUCCESS, GET_SIMILAR_PRODUCTS_BEGINS, GET_SIMILAR_PRODUCTS_FAILURE, GET_SIMILAR_PRODUCTS_SUCCESS, GET_STORY_BEGINS, GET_STORY_FAILURE, GET_STORY_SUCCESS } from "../constants";

const INITIAL_STATE = {
    isLoadPromotionsAndOffers: false,
    offersandPromotions: [],
    isLoadCustomerOffers: false,
    customerOffers: [],
    dashboard: {
        data: {},
       
    },
    purchasehistory:[],
    purchaseType:"this_year",
    isLoadDashboard: false,
    isLoadPromotionDetails: false,
    promotionDetails: {},
    similarProducts: [],
    isLoadSimilarProducts: false,
    story: [],
    isLoadStory: false,
    isLoadQuickInsights: false,
    quickInsights: {}
}

const dashBoardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PROMOTIONS_AND_OFFERS_BEGINS:
            return { ...state, isLoadPromotionsAndOffers: true };
        case GET_PROMOTIONS_AND_OFFERS_SUCCESS:
            return { ...state, isLoadPromotionsAndOffers: false, offersandPromotions: action.data };
        case GET_PROMOTIONS_AND_OFFERS_FAILURE:
            return { ...state, isLoadPromotionsAndOffers: false };
        case GET_CUSTOMER_OFFERS_BEGINS:
            return { ...state, isLoadCustomerOffers: true };
        case GET_CUSTOMER_OFFERS_SUCCESS:
            return { ...state, isLoadCustomerOffers: false, customerOffers: action.data };
        case GET_CUSTOMER_OFFERS_FAILURE:
            return { ...state, isLoadCustomerOffers: false };

        case GET_DASHBOARD_BEGINS:
            return { ...state, isLoadDashboard: true };
        case GET_DASHBOARD_SUCCESS:
            return { ...state, isLoadDashboard: false, dashboard: {...action.data} };
        case GET_DASHBOARD_PURCHASE_HISTORY_SUCCESS:
            return { ...state, isLoadDashboard: false, purchaseHistory: action?.data?.data, purchaseType:action?.data?.purchaseType   };
        case GET_DASHBOARD_FAILURE:
            return { ...state, isLoadDashboard: false };

        case GET_PROMOTIONS_DETAILS_BEGINS:
            return { ...state, isLoadPromotionDetails: true };
        case GET_PROMOTIONS_DETAILS_SUCCESS:
            return { ...state, isLoadPromotionDetails: false, promotionDetails: action.data };
        case GET_PROMOTIONS_DETAILS_FAILURE:
            return { ...state, isLoadPromotionDetails: false };

        case GET_SIMILAR_PRODUCTS_BEGINS:
            return { ...state, isLoadSimilarProducts: true };
        case GET_SIMILAR_PRODUCTS_SUCCESS:
            return { ...state, isLoadSimilarProducts: false, similarProducts: action.data };
        case GET_SIMILAR_PRODUCTS_FAILURE:
            return { ...state, isLoadSimilarProducts: false };

        case GET_STORY_BEGINS:
            return { ...state, isLoadStory: true };
        case GET_STORY_SUCCESS:
            return { ...state, isLoadStory: false, story: action.data };
        case GET_STORY_FAILURE:
            return { ...state, isLoadStory: false };

        case GET_QUICK_INSIGHTS_BEGINS:
            return { ...state, isLoadQuickInsights: true };
        case GET_QUICK_INSIGHTS_SUCCESS:
            return { ...state, isLoadQuickInsights: false, quickInsights: action.data };
        case GET_QUICK_INSIGHTS_FAILURE:
            return { ...state, isLoadQuickInsights: false };

        default:
            return state
    }
}

export default dashBoardReducer