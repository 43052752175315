import {
  ADD_OR_REMOVE_PRODUCTS_FROM_CART_BEGINS, ADD_OR_REMOVE_PRODUCTS_FROM_CART_FAILURE, ADD_OR_REMOVE_PRODUCTS_FROM_CART_SUCCESS,
  CLEAR_CART_LIST_SUCCESS, GET_CART_LIST_BEGINS, GET_CART_LIST_FAILURE, GET_CART_LIST_SUCCESS, GET_UNIT_LIST_BEGINS, GET_UNIT_LIST_FAILURE, GET_UNIT_LIST_SUCCESS,
  PLACE_ORDER_BEGINS, PLACE_ORDER_FAILURE, PLACE_ORDER_SUCCESS, UPDATE_CART_ITEMS_COUNT_SUCCESS, UPDATE_CART_ITEM_BEGINS, UPDATE_CART_ITEM_FAILURE,
  UPDATE_CART_ITEM_SUCCESS, UPDATE_PRODUCT_DESCRIPTION_BEGINS, UPDATE_PRODUCT_DESCRIPTION_FAILURE, UPDATE_PRODUCT_DESCRIPTION_SUCCESS,
} from "../constants";

const INITIAL_STATE = {
  isLoading: false,
  isLoadCart: false,
  cart: {
    data: [],
    total: 0,
  },
  placeOrderId: "",
  placeOderLoading: false,
  updateQuantityLoading: false,
  cartAddOremoveLoader: false,
  addproductDescriptionLoader: false,
  unitLists: [],
  isUnitLoading: false,
};

const concatMultiplearrays = (array1, array2) => {
  const uniq = {};
  const sum = [...array1, ...array2]
  const uniqueElements = sum?.filter(obj => !uniq[obj?.product_item_id] && (uniq[obj?.product_item_id] = true));
  return [...uniqueElements] || []
}


const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CART_LIST_BEGINS:
      return { ...state, isLoadCart: true };
    case GET_CART_LIST_SUCCESS:
      return {
        ...state, isLoadCart: false, cart: {
          data: concatMultiplearrays(state?.cart?.data || [], action.data.data),
          total: action.data.total,
        }
      };
    case GET_CART_LIST_FAILURE:
      return { ...state, isLoadCart: false };

    case UPDATE_CART_ITEM_BEGINS:
      return { ...state, updateQuantityLoading: true };
    case UPDATE_CART_ITEM_SUCCESS:
      return { ...state, updateQuantityLoading: false };
    case UPDATE_CART_ITEM_FAILURE:
      return { ...state, updateQuantityLoading: false };

    case PLACE_ORDER_BEGINS:
      return { ...state, placeOderLoading: true };
    case PLACE_ORDER_SUCCESS:
      return { ...state, placeOderLoading: false, placeOrderId: action.data };
    case PLACE_ORDER_FAILURE:
      return { ...state, placeOderLoading: false };

    case ADD_OR_REMOVE_PRODUCTS_FROM_CART_BEGINS:
      return { ...state, cartAddOremoveLoader: true };
    case ADD_OR_REMOVE_PRODUCTS_FROM_CART_SUCCESS:
      return { ...state, cartAddOremoveLoader: false }
    case ADD_OR_REMOVE_PRODUCTS_FROM_CART_FAILURE:
      return { ...state, cartAddOremoveLoader: false }

    case UPDATE_PRODUCT_DESCRIPTION_BEGINS:
      return { ...state, addproductDescriptionLoader: true };
    case UPDATE_PRODUCT_DESCRIPTION_SUCCESS:
      return { ...state, addproductDescriptionLoader: false }
    case UPDATE_PRODUCT_DESCRIPTION_FAILURE:
      return { ...state, addproductDescriptionLoader: false }

    case CLEAR_CART_LIST_SUCCESS:
      return { ...state, cart: { data: [], total: 0 } };

    case UPDATE_CART_ITEMS_COUNT_SUCCESS:
      return { ...state, cart: { total: action.data.total, } }

    case GET_UNIT_LIST_BEGINS:
      return { ...state, isUnitLoading: true };
    case GET_UNIT_LIST_SUCCESS:
      return { ...state, isUnitLoading: false, unitLists: action.data };
    case GET_UNIT_LIST_FAILURE:
      return { ...state, isUnitLoading: false };


    default:
      return state;
  }
};

export default cartReducer;
