import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Tabs } from '../../../components'
import { SettingsHead } from './SettingsHead'
import { PersonalInfoTab, ProfileSettingsTab, UserManagementTab } from './Tabs'
import "./index.scss"
import { useDispatch, useSelector } from 'react-redux'
import { actionGetProfile, actionGetSubUsers } from '../../../actions/Settings'
import { SnackMessages } from '../../../components/Toast'

const Settings = () => {
  const dispatch = useDispatch()
  const { subUsers, profile } = useSelector(state => state?.settings);
  const { user } = useSelector(state => state?.authReducer);
  const { showErrorMessage } = SnackMessages()
  const [selectedTab, setSelectedTab] = useState('Personal Info')
  const loginResponse = JSON.parse(localStorage.getItem("loginResponse"));
  const canEditMyProfile = loginResponse?.sub_user_permissions?.includes("canEditMyProfile");
  const tabOptions = [
    { title: 'Personal Info', children: <PersonalInfoTab profile={profile} />, disabled: false },
    { title: 'Profile Settings', children: <ProfileSettingsTab notification={profile?.notification_settings} />, disabled: JSON.parse(localStorage.getItem("loginResponse"))?.role_id === 5 ? !canEditMyProfile : false },
    // { title: 'User Management', children: <UserManagementTab data={subUsers} />, disabled: false },
  ]

  const fetchSubUsers = useCallback(async () => {
    const res = await dispatch(actionGetSubUsers())
    if (!res?.success) {
      showErrorMessage(res?.message)
    }
  }, [dispatch, showErrorMessage])

  const fetchProfile = useCallback(async () => {
    const res = await dispatch(actionGetProfile())
    if (!res?.success) {
      showErrorMessage(res?.message)
    }
  }, [dispatch, showErrorMessage])

  const handleTabSelect = (key) => {
    setSelectedTab(key)
    switch (key) {
      case "User Management":
        fetchSubUsers()
        break;

      default:
        break;
    }
  }

  const getMembershipType = (point) => {
    const pointValue = Number(point);
    if (pointValue >= 3000000) {
        return "diamond";
    } else if (pointValue >= 1000000) {
        return "platinum";
    } else if (pointValue >= 500000) {
        return "gold";
    } else if (pointValue >= 250000) {
        return "silver";
    } else if (pointValue < 250000) {
        return "bronze";
    } else {
        return "";
    }
};


  useEffect(() => {
    fetchProfile()
    document.title = 'Tab Surfaces customer - Settings';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='settings'>
      <SettingsHead type={getMembershipType(user?.loyality_amount)} user={user} />
      <div className='settings-body'>
        <Row className='h-50 flex-fill'>
          <Col xs={12} className={'flex-fill-column'}>
            <div className='d-flex h-100'>
              <Tabs data={tabOptions}
                variant="tab-with-border"
                onChange={handleTabSelect}
                selected={selectedTab}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default React.memo(Settings);